<template>
  <div id="upgradeMember">
    <img class="upgradeMember-backage" :src="imgurl"/>

    <div class="upgradeMember-context">
      <div class="tip">
        {{tip}}
      </div>
      <div class="amount"><b>￥{{amount}}</b></div>

      <button class="upgrade-btn" v-on:click="upLevel">立即购买</button>

      <div class="tips">
        <p>温馨提醒:</p>
        <p>1.付费升级后不可退款或解除服务</p>
        <p>2.所有刷单行为都是骗子请不要上当受骗</p>
        <p>3.慢充价格会跟随市场价格变动,或部分城市不可充值</p>
        <p>4.升级后自己或粉丝在公众号充值所有产品,均有返利</p>
        <p style="color: red;" v-on:click="mfup">5.点击这里{{upMsg}}升级</p>
      </div>
    </div>

    <div class="footer">
      <p>本服务由初柠网络科技有限公司提供技术支持</p>
    </div>
  </div>
</template>

<script>
import upgradeMember from '../assets/images/upgradeMember.png';
import mupgradeMember from '../assets/images/mupgradeMember.png';
export default {
  data(){
    return{
      amount:16.18,
      imgurl:mupgradeMember,
      upMsg:'',
      tip:'微信公众平台认证服务号!三网运营商官方合作伙伴'
    }
  },
  name: "UpgradeMember",
  created(){
    this.info();
  },
  methods:{
    upLevel(){
      console.log(1);
              let pay_type = this.isWeiXin();
            this.axios.post(`/app/user/upLevel`,{
              type:this.upMsg==="免费"?"付费":"免费",
              payType:pay_type?"Z0":"A0"
            }).then((response) => {
              if(response==500){
                return
              }
              //文字显示试免费，是付费的
              if(this.upMsg==="免费"){
                if(response!=undefined && response!='' && response!=401 && response!=500 && pay_type){
                    window.location.href = response;
                }
                if(response!=undefined && response!='' && response!=401 && response!=500 && !pay_type){
                    document.write(response)
                }
              }
              //文字显示试付费，是免费的
              if(this.upMsg==="付费" && response==="SUCCESS"){
                this.$toast.success('恭喜您,升级成功!');
                this.$router.push("/");
              }


            }).catch(function (error) {
                console.log(error);
            });
    },
    info(){
        this.axios.post(`/app/config/up_level`,{}).then((response) => {
              if(response==500){
                return
              }
              let data = JSON.parse(response);
              if('免费'===this.upMsg){
                this.upMsg = '付费';
                this.imgurl = mupgradeMember;
                this.amount = 0.00;
                this.tip = `推荐${data.count}人注册关注绑定即可免费升级!`
              }else if('付费'===this.upMsg){
                this.upMsg = '免费';
                this.imgurl = upgradeMember;
                this.amount = data.amount;
                this.tip = '微信公众平台认证服务号!三网运营商正规官方合作'
              }else{
                this.upMsg = '免费';
                this.imgurl = upgradeMember;
                this.amount = data.amount;
                this.tip = '微信公众平台认证服务号!三网运营商正规官方合作'
              }

            }).catch(function (error) {
                console.log(error);
            });
    },
    mfup(){
      this.info();
    },
    isWeiXin(){
        //获取浏览器属性
        //window.navigator.userAgent属性包含了浏览器类型、版本、操作系统类型、浏览器引擎类型等信息，这个属性可以用来判断浏览器类型
        let ua = window.navigator.userAgent.toLowerCase();
        //通过正则表达式匹配ua中是否含有MicroMessenger字符串
        if(ua.match(/MicroMessenger/i) == 'micromessenger'){
            return true;
        }
        return false;
    }

  },
  beforeCreate () {
    document.querySelector('body').setAttribute('style', 'background:#f5f5f5')
  },
  beforeDestroy () {
    document.querySelector('body').removeAttribute('style')
  }
}
</script>

<style scoped>
  #upgradeMember{
    height: 1000px;
    background-color: #77f1bb;
  }
  .upgradeMember-backage{
    width: 100%;
  }
  .upgradeMember-context{
    position: relative;
    margin: -55px 3%;
    padding: 2%;
    width: 90%;
    border-radius: 20px;
    background: #FFFFFF;
  }
  .tip{
    font-size: 12px;
    padding: 10px 0;
    color: #FFFFFF;
    margin: -23px 20px 0 20px;
    text-align: center;
    background: #77f1bb;
    box-shadow:7px 10px 6px #b8e9d3;
  }
  .amount{
    color: red;
    font-size: 32px;
    margin: 2rem 0;
    text-align: center;
  }
  .upgrade-btn{
    width: 90%;
    height: 45px;
    margin: 0 5%;
    border:none;
    color: #FFFFFF;
    background: #77f1bb;
    border-radius: 45px;
  }
  .tips{
    font-size: 12px;
  }
.tips p{
  margin: 8px 3%;
}
.footer{
  color: #FFFFFF;
  margin-top: 65px;
  font-size: 12px;
  text-align: center;
}
</style>
